<template>
  <div id="register">
    <div class="brand">
      <img src="../../../assets/images/logo.png" alt="" />
      <p>亿师傅师傅版</p>
    </div>
    <div class="registeredWrap">
      <P style="font-size: 1.8rem; font-weight: bold; padding: 0 0 1rem 0">用户注册</P>

      <div class="public">
        <div class="registeredIcon">
          <svg t="1602041080197" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1221" width="26" height="26">
            <path d="M651.3 207.5H372.7c-25.4 0-46 20.6-46 46v517c0 25.4 20.6 46 46 46h278.6c25.4 0 46-20.6 46-46v-517c0-25.4-20.6-46-46-46z m26 563c0 14.3-11.7 26-26 26H372.7c-14.3 0-26-11.7-26-26v-517c0-14.3 11.7-26 26-26h278.6c14.3 0 26 11.7 26 26v517z" fill="#8a8a8a" p-id="1222"></path>
            <path d="M569.3 253.9H454.7c-5.5 0-10 4.5-10 10s4.5 10 10 10h114.5c5.5 0 10-4.5 10-10s-4.4-10-9.9-10z" fill="#8a8a8a" p-id="1223"></path>
            <path d="M512 759.8m-23.4 0a23.4 23.4 0 1 0 46.8 0 23.4 23.4 0 1 0-46.8 0Z" fill="#8a8a8a" p-id="1224"></path>
          </svg>
        </div>
        <p v-if="false">{{ subAct }}</p>
        <input type="text" placeholder="输入手机号" v-model="userPhone" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" />
      </div>
      <div class="public">
        <div class="getCode">
          <button class="getCode-button" @click="getCode" v-if="show" :disabled="getCodeButtonDisabled">获取验证码</button>
          <span v-else style="font-size: 1.4rem">{{ countdown }}s</span>
        </div>
        <input type="text" placeholder="输入验证码" id="inputCode" v-model="userCode" maxlength="4" />
      </div>
      <div class="public" v-if="eyeShow">
        <input type="password" placeholder="输入密码" id="inputCode" v-model="userPassword" maxlength="20" />
        <div class="getCode eyeShow">
          <span @click="changeEyes" style="font-size: 1.4rem">
            <svg t="1604297531759" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5724" width="22" height="22">
              <path d="M508.5 228.6C235.8 228.6 71.1 507 71.1 507s190.3 269.8 437.4 269.8S945.9 507 945.9 507 781.1 228.6 508.5 228.6z m286.7 382.7c-92.7 78.2-191.1 127.4-289.6 127.4S308.7 692.5 216 611.3c-31.8-29-63.7-57.9-89.7-89.7-5.8-5.8-8.7-11.6-14.5-17.3 2.9-5.8 8.7-11.6 11.6-17.3 23.1-31.8 52.1-63.7 84-92.7C297.1 313.2 395.6 264 505.6 264s208.5 49.2 298.2 130.3c31.8 29 57.9 60.8 84 92.7 2.9 5.8 8.7 11.6 11.6 17.3-2.9 5.8-8.7 11.6-14.5 17.3-26 28.9-55 60.8-89.7 89.7z" fill="#757575" p-id="5725"></path>
              <path d="M505.6 352.6c-76.1 0-134.5 60.9-134.5 134.5 0 76.1 60.9 134.5 134.5 134.5 76.1 0 134.5-60.9 134.5-134.5s-60.9-134.5-134.5-134.5z m0 233.4c-53.3 0-96.4-43.2-96.4-96.4s43.2-96.4 96.4-96.4 96.4 43.2 96.4 96.4c0 50.8-43.1 96.4-96.4 96.4z" fill="#757575" p-id="5726"></path>
            </svg>
          </span>
        </div>
      </div>
      <div class="public" v-else>
        <input type="text" placeholder="输入密码" id="inputCode" v-model="userPassword" maxlength="20" />
        <div class="getCode">
          <span @click="changeEyes" style="font-size: 1.4rem">
            <svg t="1604297745391" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11371" width="20" height="20">
              <path d="M972 366.8h-52.8c-20.8 39.7-46.6 76.1-77.1 108.7-42.9 45.9-92.8 82-148.4 107.2C636.2 608.7 575 622 512 622s-124.2-13.2-181.7-39.3c-55.6-25.2-105.5-61.2-148.4-107.2-30.5-32.6-56.2-69-77.1-108.7H52c28.1 60.5 66.4 114.7 112.4 160L76.8 638.5l35.8 32.1 87.5-111.7c34.9 28.6 73.3 52.5 114.5 70.9L268 766.7l43.9 17.1 46.5-136.9c41.4 13.9 85.1 22.3 130.2 24.4v145.8h46.7V671.4c45.2-2.2 88.8-10.6 130.2-24.4L712 783.9l43.9-17.1-46.5-136.9c41.1-18.4 79.6-42.3 114.5-70.9l87.5 111.7 35.8-32.1-87.5-111.7c45.9-45.4 84.2-99.6 112.3-160.1z" fill="#757575" p-id="11372"></path>
            </svg>
          </span>
        </div>
      </div>
      <!-- <div class="public">
        <input
          type="password"
          placeholder="确认密码"
          id="inputCode"
          v-model="surePassword"
          maxlength="20"
        />
      </div> -->
      <div class="public">
        <button @click="submit" style="border: 1px solid #ff816" :class="subAct ? 'act' : ''">
          点击注册
        </button>
      </div>
      <div class="public">
        <p>
          <span style="font-size: 1.4rem">已注册，</span><span style="color: #ff816c; font-size: 1.5rem" @click="goSubPage('login')">去登陆</span>
        </p>
      </div>
      <!-- <div class="public">
        <button
          @click="goLogin"
          style="background: #f5f5f5; border: 1px solid #f5f5f5; color: #000"
        >
          账号登录
        </button>
      </div> -->
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 2,
      userPhone: "",
      userCode: "",
      countdown: 60,
      show: true,
      responseMessage: 0,
      userPassword: "",
      eyeShow: true,
      getCodeButtonDisabled: false,
    };
  },
  methods: {
    changeEyes() {
      this.eyeShow = !this.eyeShow;
    },

    getCode() {
      if (!/^1[3456789]\d{9}$/.test(this.userPhone)) {
        Toast("请输入正确的手机号码");
      } else {
        this.$ajax
          .post(`${this.http_axios}/api/v2/common/sms/get_code`, {
            mobile: this.userPhone,
            scene: "worker_register",
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.getCodeButtonDisabled = true;
              console.log(res);
              this.countdown = 60;
              this.show = false;
              this.timer = setInterval(() => {
                this.countdown--;
                if (this.countdown === 0) {
                  this.show = "false";
                  this.getCodeButtonDisabled = false;
                  clearInterval(this.timer);
                }
              }, 1000);
              Toast(res.data.message);
              // alert('发送成功')
            }
          })
          .catch((err) => {
            if (err.response) {
              // console.log(err.response.data);
              Toast(err.response.data.error);
            }
          });
      }
    },

    submit() {
      if (this.userPhone.length != 11) {
        Toast("请填写11位手机号码");
      } else if (this.userCode.length != 4) {
        Toast("请填写4位数验证码");
      } else if (
        this.userPassword.length < 6 ||
        this.userPassword.length > 20
      ) {
        Toast("请输入6-20位数字或者字母组成的密码");
      } else {
        this.$ajax
          .post(`${this.http_axios}/api/v2/worker/auth/register`, {
            mobile: this.userPhone,
            scene: "worker_register",
            code: this.userCode,
            password: this.userPassword,
          })
          .then((res) => {
            if (res.data.access_token != "") {
              console.log(res, "注册成功返回值");
              Toast("注册成功");
              window.localStorage.setItem("token", res.data.access_token);
              // this.goSubPage("initialize_data");
              this.goSubPage("home");
            }
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.status === 412) {
              Toast(err.response.data.error);
            }
          });
      }
    },
  },
  mounted() {},
  computed: {
    subAct() {
      if (
        this.userPhone != "" &&
        this.userCode != "" &&
        this.userPassword != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="less">
#register {
  .brand {
    width: 100%;
    padding: 4rem 0 12rem 0;
    background: #ff816c url("../../../assets/1.png") no-repeat bottom / cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 30%;
    }
    p {
      font-size: 1.4rem;
      color: #fff;
      padding: 0.5rem;
    }
  }
  .registeredWrap {
    box-sizing: border-box;
    width: 90%;
    // padding: 20rem 0;
    padding: 2rem 0;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    left: calc(50% - 45%);
    top: 14rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .public {
      width: 88%;
      height: 4.6rem;
      padding: 1rem;
      position: relative;
      .registeredIcon {
        position: absolute;
        left: 2rem;
        top: calc(50% - 13px);
      }
      .getCode {
        height: 3rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1.5rem);
        line-height: 3rem;
        &.eyeShow {
          top: calc(50% - 1.1rem);
        }
        .getCode-button {
          border: none;
          background: none;
          color: #000;
          font-size: 1.3rem;
        }
      }
      input,
      button {
        width: 100%;
        height: 100%;
        border: 1px solid #dfdfdf;
        border-radius: 40rem;
        text-indent: 4rem;
        font-size: 1.4rem;
      }

      button {
        background: #dfdfdf;
        text-indent: 0;
        color: #fff;
        &.act {
          background: #ff816c;
          border: 1px solid #ff816c;
        }
      }
    }
  }
}
</style>